
.blockIcon {
    position: relative;
    display: inline-flex;
  }
.iconTag {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 24px !important;
    height: 24px !important;
  }
