.App {}

.content-container {
    height: calc(100vh - 110px);
    overflow: scroll;
    width: 100%;
    margin-top: 80px;
    margin-left: 12px;
}


/* .header_offset {
    margin-top: 40px;
    margin-left: 10px;
} */

.footer--pin {
    position: relative;
    left: 0;
    bottom: 0;
}

footer {
    background-color: #b72828;
    padding: 5px 10px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer div {
    background-color: #950707;
    border-radius: 30px;
    width: 555px;
    height: 50px;
}

footer p {
    color: #e3c893;
    font-family: Montserrat, Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
}

footer ul li {
    background-color: #950707;
    border-radius: 30px;
    width: 15px;
    height: 15px;
}

.login-form {
    justify-content: center;
    min-height: 90vh;
}

.button-block {
    width: 100%;
}

.login-background {
    justify-content: center;
    min-height: 30vh;
    padding: 50px;
}

.std-button {
    font-size: .825em;
    text-decoration: none;
    font-weight: 700;
    padding: .35em 1em;
    background-color: #edf0f1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .1));
    border-radius: 3px;
    color: rgba(0, 0, 0, .6);
    text-shadow: 0 1px 1px rgb(255 255 255 / 70%);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 20%), 0 1px 2px rgb(0 0 0 / 20%), inset 0 1px 2px rgb(255 255 255 / 70%);
}

.std-button:hover,
.std-button.hover {
    background-color: #fff;
}

.std-button:active,
.std-button.active {
    background-color: #d0d3d6;
    background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, 0));
    box-shadow: inset 0 0 2px rgb(0 0 0 / 20%), inset 0 2px 5px rgb(0 0 0 / 20%), 0 1px rgb(255 255 255 / 20%);
}

.menuCard { 
    max-width: 345
}
.menuCardMedia { 
    height: 140
}
.center50 {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
.center90 {
    margin: auto;
    width: 90%;
    padding: 10px;
  }