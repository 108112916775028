.dropdown {
    position: relative;
    display: inline-flex;
}

.button {
    background-color: #3f51b5;
    color: #ffffff;
    border-radius: 4px;
    outline: none;
    user-select: none;
    height: 40px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    transition: filter 160ms ease-out;
    border: none;
    padding: 0 16px;
    font-weight: 400;
    font-size: 14px;
}

.button:hover {
    filter: brightness(0.9);
}

.active {
    filter: brightness(0.9);
}

.disabled {
    cursor: not-allowed;
}

.disabled:hover {
    filter: brightness(1) !important;
}

.menu {
    position: absolute;
    top: 44px;
    right: 0;
    z-index: 1000;
    flex-direction: column;
    min-width: 230px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.2);
}

.menu>ul {
    background-color: #3f51b5;
    color: #ffffff;
    list-style: none;
    padding-left: 0;
    /*margin-top: 12px;*/
    /*margin-bottom: 12px;*/
}

.menuRight {
    left: 0;
}